<template>
  <div class="row">
    <div v-if="!param.vendorFlag" class="col-12">
    <!-- 위험등록부 목록 -->
      <c-table
        ref="tableRisk"
        title="위험등록부 목록"
        tableId="riskRegister01"
        :columns="gridRisk.columns"
        :data="riskReduce.riskbooks"
        :merge="gridRisk.merge"
        gridHeight="auto"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="false"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='riskHazardPicture'">
            <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-success" 
            name="image"
            @click.stop="openHazardPicture(props)" />
            <!-- <c-upload-picture
              class="scenario4m"
              height="35px"
              :attachInfo="{
                isSubmit: '',
                taskClassCd: 'RISK_HAZARD',
                taskKey: props.row.ramRiskHazardId,
              }"
            ></c-upload-picture> -->
          </template>
          <template v-else-if="col.name==='picture'">
            <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-primary" 
            name="image"
            @click.stop="openImprPicture(props)" />
          </template>
          <template v-else-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="false"
              :requestContentsCols="requestContentsCols"
              tableKey="ram4mAssessScenarioId"
              ibmTaskTypeCd="ITT0000023"
              ibmTaskUnderTypeCd="ITTU000030"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-12">
      <!-- 개선 목록 -->
      <c-table
        ref="tableImpr"
        title="개선 목록"
        tableId="impr01"
        :columns="gridImpr.columns"
        :data="riskReduce.imprs"
        :merge="gridImpr.merge"
        gridHeight="auto"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="false"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='riskHazardPicture'">
            <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-success" 
            name="image"
            @click.stop="openHazardPicture(props)" /> 
            <!-- <c-upload-picture
              class="scenario4m"
              height="35px"
              :attachInfo="{
                isSubmit: '',
                taskClassCd: 'RISK_HAZARD',
                taskKey: props.row.ramRiskHazardId,
              }"
            ></c-upload-picture> -->
          </template>
          <template v-else-if="col.name==='picture'">
            <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-primary" 
            name="image"
            @click.stop="openImprPicture(props)" />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fm-register-TL',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramStepCd: '',
          vendorFlag: false,
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      riskReduce: {
        imprs: [],
        riskbooks: [],
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processJob' },
          { index: 2, colName: 'ram4mRiskHazardClassGroup' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: '공정',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            // 평가구분(4M)
            label: '평가구분(4M)',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: '사진',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 유해위험요인
            label: '유해위험요인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: '관련법규',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTargetName',
            field: 'damageTargetName',
            // 피해대상
            label: '피해대상',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTypeName',
            field: 'damageTypeName',
            // 재해형태
            label: '재해형태',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전조치
            label: '현재안전조치',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: '빈도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: '강도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                type: 'proxy',
                sortable: false,
              },
            ]
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            // 추가 리스크관리 계획
            label: '개선 대책',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          // {
          //   name: 'improveFlagName',
          //   field: 'improveFlagName',
          //   // 개선<br/>진행<br/>여부
          //   label: '개선<br/>진행<br/>여부',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          // },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: '빈도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: '강도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                type: 'proxy',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: '평가자',
            align: 'center',
            userId: 'assessUserId',
            style: 'width:80px',
            sortable: false,
          },
        ],
        height: 'auto'
      },
      gridImpr: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'groupMdmSopId' },
          { index: 2, colName: 'ram4mRiskHazardClassGroup' },
          { index: 3, colName: 'ram4mAssessScenarioId' },
          { index: 4, colName: 'ram4mAssessScenarioId' },
          { index: 5, colName: 'ram4mAssessScenarioId' },
          { index: 6, colName: 'ram4mAssessScenarioId' },
          { index: 7, colName: 'ram4mAssessScenarioId' },
          { index: 8, colName: 'ram4mAssessScenarioId' },
          { index: 9, colName: 'ram4mAssessScenarioId' },
          { index: 10, colName: 'ram4mAssessScenarioId' },
        ],
        columns: [],
        data: [],
        highLightInfo: {
          val: 'scenarioId',
          ids: [],
        },
        height: 'auto'
      },
      colorItems: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 20) + 'px' : '500px'
    },
    imprComponent() {
      let components = null;
      if (!this.param.vendorFlag) {
        components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      } else {
        components = () => import(`${'@/pages/ram/4m/4mTableImpr.vue'}`);
      }
      return components;
    },
    requestContentsCols() {
      return ['jobName', 'ram4mRiskHazardClassName', 'riskHazardName'];
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.fm.riskReduce.list.url;
      // code setting
      if (!this.param.vendorFlag) {
        this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
          this.gridImpr.columns = [
            {
              name: 'processName',
              field: 'processName',
              // 공정
              label: '공정',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'jobName',
              field: 'jobName',
              // 작업
              label: '작업',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'ram4mRiskHazardClassName',
              field: 'ram4mRiskHazardClassName',
              // 평가구분(4M)
              label: '평가구분(4M)',
              align: 'center',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'riskHazardPicture',
              field: 'riskHazardPicture',
              // 사진
              label: '사진',
              align: 'center',
              style: 'width:40px',
              sortable: false,
              type: 'custom',
            },
            {
              name: 'riskHazardName',
              field: 'riskHazardName',
              // 유해위험요인
              label: '유해위험요인',
              align: 'left',
              style: 'width:170px',
              sortable: false,
            },
            // {
            //   name: 'relationLaw',
            //   field: 'relationLaw',
            //   // 관련법규
            //   label: '관련법규',
            //   align: 'left',
            //   style: 'width:120px',
            //   sortable: false,
            // },
            // {
            //   name: 'damageTargetName',
            //   field: 'damageTargetName',
            //   // 피해대상
            //   label: '피해대상',
            //   align: 'left',
            //   style: 'width:120px',
            //   sortable: false,
            // },
            {
              name: 'damageTypeName',
              field: 'damageTypeName',
              // 재해형태
              label: '재해형태',
              align: 'left',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'existingRiskManagementActivities',
              field: 'existingRiskManagementActivities',
              // 현재안전조치
              label: '현재안전조치',
              align: 'left',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'picture',
              field: 'picture',
              label: '개선 전/후 사진',
              align: 'center',
              type: 'custom',
              style: 'width:60px',
              sortable: false,
            },
            // {
            //   name: 'improveFlagName',
            //   field: 'improveFlagName',
            //   // 개선<br/>진행<br/>여부
            //   label: '개선<br/>진행<br/>여부',
            //   align: 'center',
            //   style: 'width:100px',
            //   sortable: false,
            // },
            {
              name: 'addingRiskManagementActivities',
              field: 'addingRiskManagementActivities',
              // 추가 리스크관리 계획
              label: '개선 대책',
              align: 'left',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'ibmTitle',
              field: 'ibmTitle',
              // 제목
              label: '제목',
              align: 'left',
              type: 'link',
              style: 'width:200px',
              sortable: true,
            },
            {
              name: 'ibmStepCd',
              field: 'ibmStepCd',
              // 진행상태
              label: '진행상태',
              align: 'center',
              style: 'width:80px',
              type: 'tag',
              colorItems: _result,
              sortable: false
            },
            {
              name: 'improveRequest',
              field: 'improveRequest',
              // 요청부서정보
              label: '요청부서정보',
              align: 'left',
              style: 'width:220px',
              sortable: true,
            },
            // {
            //   name: 'actionCompleteRequestDate',
            //   field: 'actionCompleteRequestDate',
            //   // 조치완료요청일
            //   label: '조치완료요청일',
            //   align: 'center',
            //   style: 'width:100px',
            //   sortable: true,
            // },
            {
              name: 'actionDeptName',
              field: 'actionDeptName',
              // 조치부서
              label: '조치부서',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
          ]
          this.colorItems = _result
        });
      } else {
        this.gridImpr.columns = [
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            // 평가구분(4M)
            label: '평가구분(4M)',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: '사진',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 유해위험요인
            label: '유해위험요인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: '관련법규',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTargetName',
            field: 'damageTargetName',
            // 피해대상
            label: '피해대상',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTypeName',
            field: 'damageTypeName',
            // 재해형태
            label: '재해형태',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전조치
            label: '현재안전조치',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선 전/후 사진
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'improveFlagName',
            field: 'improveFlagName',
            // 개선<br/>진행<br/>여부
            label: '개선<br/>진행<br/>여부',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            // 추가 리스크관리 계획
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'stepFlagName',
            field: 'stepFlagName',
            // 진행상태 
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            // 조치일
            label: '조치일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ]
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    /* eslint-disable no-unused-vars */
    imprChange(data) {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      if (!this.param.vendorFlag) {
        this.popupOptions.param = {
          sopImprovementId: row.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.param = {
          ram4mAssessImprId: row.ram4mAssessImprId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ram/4m/risk/4mImprRiskReduceDetail.vue'}`);
      }
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    openHazardPicture(props) {
      this.popupOptions.title = '유해위험요인 사진'; // 유해위험요인 사진
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진'; // 개선 사진
      this.popupOptions.param = {
        ram4mAssessScenarioId: props.row.ram4mAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        ram4mAssessImprIds: props.row.ram4mAssessImprIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/4m/action/4mScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>